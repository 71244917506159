.stock-buttons {
  margin-left: 40px;

  .ui.button {
    width: 240px;
    margin-bottom: 16px !important;

    &.salmon {
      color: #fff;
      background: #d44343;
    }

    &.orange {
      color: #fff;
      background: #ffc107;
      &:hover,
      &:active,
      &:focus {
        background: lighten(#ffc107, 10);
      }
    }
  }
}
