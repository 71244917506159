.a__search {
  display: flex;
  min-height: 120px;
  padding: {
    top: 10px;
    right: 20px;
    bottom: 10px;
    left: 20px;
  }

  background: white;
  box-shadow: 0 0 2px #bbbbbb;
  border: {
    radius: 6px;
  }
  margin: {
    bottom: 8px;
  }
}
