.b__search-result {
  margin: {
    top: 8px;
  }

  padding: {
    top: 8px;
    right: 10px;
    bottom: 8px;
    left: 10px;
  }

  border: 1px #efefef solid;
  border-radius: 6px;

  .w__label {
    cursor: pointer;
    &:hover {
      opacity: 0.9;
    }
  }
}
