.chat-modal {
  &__message {
    padding: 32px 0 24px;
    text-align: center;
  }
  &__form {
    p {
      margin-bottom: 32px;
      text-align: right;
    }

    .form__error {
      font-size: 14px;
      color: #d95858;
    }
  }
}
