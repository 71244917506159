@import '../../../styles/index.scss';

.a__form-search {
  display: flex;
  flex-direction: column;

  .bf__input {
    display: flex;

    .b__search-input {
      display: flex;
      flex: 3;

      .input {
        width: 100%;
        border-radius: 4px;
        box-shadow: 0 0 2px rgba($form__main-color, 0.2);

        &.outline {
          input {
            box-shadow: 0 0 2px rgba($form__main-color, 1);
          }
        }

        input {
          border: none;
          background: {
            color: $form__white-color;
          }

          &:focus {
            background: {
              color: $form__white-color;
            }

            box-shadow: 0 0 0 3px rgba($form__main-color, 0.7);
          }

          &:active {
            background: {
              color: $form__white-color;
            }

            box-shadow: 0 0 0 3px rgba($form__main-color, 0.7);
          }
        }

        &.disabled {
          opacity: 0.4;
          background: {
            color: rgba($app__main-color, 0.1);
          }
        }
      }
    }

    .b__button {
      display: flex;
      flex: 1;
      box-shadow: 0 0 30px #e9ecef;
      margin: {
        left: 10px;
      }
    }
  }

  .b__result {
    min-height: 65px;
    padding: 8px 12px;
    margin-top: 10px;
    box-shadow: 0 0 30px #e9ecef;
    border: {
      radius: 4px;
    }
    background: {
      color: $form__white-color;
    }

    .title {
      font-size: 12px;
    }
  }

  .ui {
    &.label {
      cursor: pointer;
      margin: {
        top: 2px;
        bottom: 2px;
      }

      &:hover {
        opacity: 0.92;
      }
    }
  }
}
