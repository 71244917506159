.a__card-icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 180px;
  height: 180px;
  padding: 10px;
  margin: 8px;
  background-color: white;
  border-radius: 6px;
  box-shadow: 0 0 8px #ddd;
  .mark {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    height: 56px;
    i {
      position: relative;
      bottom: 6px;
    }
  }
  .num {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .primary {
      font: {
        size: 28px;
        weight: 900;
      }
    }

    .unit {
      font: {
        weight: 900;
      }
    }

    .note {
      font: {
        size: 10px;
        weight: 900;
      }

      position: relative;
      bottom: 4px;
      color: #aaa;
    }

    .other {
      display: flex;
      flex: 1;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font: {
        size: 12px;
        weight: bold;
      }
      &.plus {
        color: #21bf73;
      }
      &.minus {
        color: #ce0f3d;
      }
    }
  }
}
