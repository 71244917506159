.a__information-row {
  margin: {
    top: 8px;
  }
  .label {
    font: {
      weight: bold;
      size: 18px;
    }
  }
  .value {
    font: {
      size: 14px;
    }
  }
}
