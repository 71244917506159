.a__dropdown {
  width: 100%;

  .ui.dropdown {
    display: flex !important;
    align-items: center;
    height: auto;
    min-height: 42px;
  }

  .text {
    line-height: 1;
  }
}
