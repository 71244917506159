@import "../../../styles/index.scss";

.a__stocks-main {
  flex: 1;
  padding: {
    right: 24px;
    left: 24px;
  }

  .b-stocks__title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;

    .ui.button {
      color: #fff;
      background: #eaaf00;
    }
  }

  .b-stocks__count {
    font-size: 14px;
    font-weight: 700;

    span {
      margin: 0 3px;
      color: #eaaf00;
    }
  }
}
