@import '../../../styles/index.scss';

.gc__pagination {
  display: flex;
  justify-content: flex-end;
  .rc-pagination-item {
    &:hover {
      border-color: $app__main-color;
      a {
        color: $app__main-color;
        text-decoration: none;
      }
    }

    &:focus {
      outline: none;
    }
  }

  .rc-pagination-item-active {
    background-color: $app__main-color;
    border-color: $app__main-color;
    &:hover {
      a {
        color: white;
      }
    }
  }

  .rc-pagination-prev,
  .rc-pagination-next {
    border: none;
    outline: none;
  }

  .rc-pagination-item-link {
    text-decoration: none;
  }
  .rc-pagination-jump-prev,
  .rc-pagination-jump-next {
    pointer-events: none;
  }

  .rc-pagination-disabled {
    pointer-events: none;
  }
}
