.a__input__default {
  .ui.input {
    width: 100%;

    input {
      height: 40px;
    }

    &.disabled {
      cursor: not-allowed;
    }
  }

  .ui.basic.label {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 34px;
    height: 40px;
    font-size: 1em;
    color: #222;
  }
}
