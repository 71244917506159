.a__chat-message {
  display: flex;
  align-items: center;
  margin-top: 16px;

  &__user-name {
    width: 160px;
    margin-right: 16px;
    font-size: 14px;
    text-align: center;
    word-break: break-all;
  }

  &__body {
    position: relative;
    display: block;
    width: 100%;
    min-height: 27px;
    padding: 4px 16px;
    background: #fffbed;
    border-radius: 7px;

    &::before {
      position: absolute;
      top: 10px;
      left: -18px;
      margin-top: -8px;
      content: '';
      border: 8px solid transparent;
      border-right: 12px solid #fffbed;
    }
  }
}
