@import "../../../styles/index.scss";

.a__shipping-costs-main {
  flex: 1;
  padding: {
    right: 24px;
    left: 24px;
  }

  .b__search {
    width: 100% !important;
  }

  .b__title {
    display: flex;
    justify-content: space-between;

    .a__button-block {
      &:not(:nth-of-type(1)) {
        margin-top: 16px !important;
      }

      .ui.button {
        min-width: 120px;
        margin-top: 20px;
      }
    }

    .b__title-container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .title {
      font-family: 'UD Shin Go Medium', sans-serif;
    }

    .b__title-tips {
      margin-top: 20px;
    }

    .error {
      margin-top: 30px;
      color: $app__error-color;
    }
  }

  .b__sizes-costs {
    .table {
      .tbody {
        min-height: 300px;
      }
    }
  }

  .b__relay-costs {
    .table {
      .tbody {
        min-height: 200px;
      }
    }
  }
}

.a__fare-table {
  font-size: 12px !important;
  border-collapse: collapse;

  .b__th {
    font-weight: 400;
    vertical-align: top;
  }

  .b__th,
  .b__td {
    padding: 3px 6px;
    border: solid 1px #d2cec4;
  }

  .b__th:first-child,
  .b__td:first-child {
    background: #fbedb5;
  }

  .b__td:first-child {
    font-weight: 900;
  }

  .b__tr:nth-child(1) .b__th {
    color: #fff;
    background: #817d78;
  }

  .b__tr:nth-child(2) .b__th:not(:first-child) {
    background: #f2f1ed;
  }
}
