.a__rating-modal {
  padding: 1.6rem 0;

  &__body {
    span {
      font-size: 20px;
    }

    textarea {
      width: 100%;
      height: 200px;
      padding: 17px 8px;
      resize: none;
      border-radius: 5px;
      outline: none;

      &:active,
      &:focus {
        border: 1px solid #85b7d9;
        box-shadow: none;
      }
    }
  }

  .b__field__row {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
  }

  .b__field {
    margin-bottom: 20px;
  }

  .caution {
    display: flex;
    justify-content: flex-end;
  }

  // label
  .rating-label {
    display: flex;
    align-items: center;

    &__optional {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 28px;
      height: 16px;
      margin-left: 10px;
      background: #ef6266;
      border-radius: 3px;

      span {
        font-size: 10px;
        color: #fff;
      }

      &.is-optional {
        background: #d8d8d8;

        span {
          color: #111;
        }
      }
    }
  }
}
