@import '../../../styles/index.scss';

.a__table-sort {
  display: flex;
  cursor: pointer;

  .sort {
    display: flex;
    flex-direction: column;
    i.icon {
      height: 0;
    }
    i.icon.sort.up::before {
      cursor: pointer;
      font: {
        size: 11px;
      }
    }
    i.icon.sort.down::before {
      position: relative;
      top: 1px;
      font: {
        size: 11px;
      }
    }
    .active {
      color: $app__main-color;
    }
  }
  &.c__th {
    &.disabled {
      .sort {
        cursor: not-allowed;
      }
    }
  }
}
