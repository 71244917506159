.a__confirm-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  .w__message {
    padding: {
      top: 32px;
      bottom: 24px;
    }
  }
  .w__modal-buttons {
    display: flex;
    flex-direction: row;

    .ui.button {
      display: flex;
      justify-content: center;
      width: 144px;
      height: 40px;
      margin: {
        right: 16px !important;
        left: 16px !important;
      }
    }
  }
}
