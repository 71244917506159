@import "../../../../styles/index.scss";

.a__sidebar {
  flex: 1;
  height: 100%;
  background: {
    color: $app__accent-color;
  }

  ul {
    padding: 0;
    margin: 0;
    list-style: none;

    li {
      a {
        display: inline-block;
        width: 100%;
        height: 100%;
        color: white;
        text-decoration: none;
        padding: {
          top: 10px;
          right: 20px;
          bottom: 10px;
          left: 0;
        }

        i.icon,
        i.icons {
          height: 1.1em;
          font-size: 1.1em;
        }

        .title {
          padding: {
            left: 20px;
          }

          color: white !important;
        }

        cursor: pointer;
        &:hover {
          background: {
            color: darken($app__accent-color, 2.5%);
          }
        }
        &.active {
          background: {
            color: darken($app__accent-color, 5%);
          }
        }
      }

      &.logo {
        height: 170px;

        .image {
          width: 60%;
          height: auto;
          background: {
            color: $app__white-color;
          }
        }

        .w__logo {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
}
