// app
$app__main-color: #eaaf00;
$app__accent-color: #245492;
$app__white-color: #fff;
$app__error-color: #d95858;
$app__warning-color: #ffe121;
$app__success-color: #5ba19b;
$app__black-color: #555;
$app__dark-color: #8f9aae;
$app__link-color: $app__main-color;
$app__title-size: 36;
$app__font-size: 16;
$app__line-height: 1.7;

$app__change-password-color: #30364a;

$gc__main-color: $app__main-color;
$gc__accent-color: #245492;
$gc__white-color: #fff;
$gc__error-color: #d95858;
$gc__warning-color: #ffe121;
$gc__success-color: #5ba19b;
$gc__black-color: #555;
$gc__dark-color: #8f9aae;

$form__main-color: $app__main-color;
$form__white-color: $app__white-color;
$form__dark-color: #efefef;
$form__error-color: $app__error-color;

// Zeplin
$white: #fff;
$very-light-pink: #d8d8d8;
$very-light-pink-two: #f0f0f0;
$very-light-pink-three: #c7c7c7;
$very-light-pink-four: #eee;
$very-light-pink-five: #ccc;
$very-light-pink-six: #e9ecef;

// min-width
$bp_up: (
  'xs': 'screen and (min-width: 0px)',
  'sm': 'screen and (min-width: 360px)',
  'md': 'screen and (min-width: 768px)',
  'lg': 'screen and (min-width: 992px)',
  'xl': 'screen and (min-width: 1366px)',
);

// max-width
$bp_down: (
  'xs': 'screen and (max-width: 359px)',
  'sm': 'screen and (max-width: 767px)',
  'md': 'screen and (max-width: 991px)',
  'lg': 'screen and (max-width: 1365px)',
  'xl': 'screen and (max-width: 10000px)',
);
