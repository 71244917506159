.a__textarea {
  textarea {
    width: 100%;
    padding: 10px 16px;
    color: rgba(0, 0, 0, 0.87);
    resize: vertical;
    border: 1px solid rgba(34, 36, 38, 0.15);
    border-radius: 4px;
    outline-style: none;

    &:focus:not(:disabled),
    &:active:not(:disabled) {
      border-color: #85b7d9;
    }

    box-shadow: none;
  }
}
