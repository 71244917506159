@import '../../../styles/index.scss';

.a__form-text-area {
  padding: {
    top: 12px;
    right: 16px;
    left: 16px;
    bottom: 12px;
  }

  width: 100%;
  height: 40px;
  height: 184px;
  line-height: $app__line-height;
  color: rgba(0, 0, 0, 0.87);
  resize: none;
  background: $form__dark-color;
  font-size: 14px;
  border: none;
  border-radius: 4px;
  transition-duration: 0.2s;
  outline: none;

  &::placeholder {
    opacity: 0.6;
  }

  &.disabled {
    cursor: not-allowed;
    opacity: 0.4;
  }
}

textarea {
  &:focus {
    box-shadow: 0 0 0 3px rgba($form__main-color, 0.7);
    background: {
      color: $form__white-color;
    }
  }

  &:active {
    box-shadow: 0 0 0 3px rgba($form__main-color, 0.7);
    background: {
      color: $form__white-color;
    }
  }
}
