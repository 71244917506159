.a__label {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: {
    top: 2px;
    left: 4px;
    bottom: 2px;
    right: 4px;
  }
  font-size: 12px;
  border-radius: 8px;

  font-weight: bold;

  &.required {
    background-color: #ef6266;
    color: white;
  }
  &.disabled {
    background-color: #d8d8d8;
    color: #616161;
  }
}
