.a__review-modal {
  display: flex;
  flex-direction: column;
  align-items: center;

  .b__rating-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .b__body-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: {
      top: 16px;
      bottom: 8px;
    }
  }

  .w__message {
    padding: {
      top: 32px;
      bottom: 24px;
    }
    text-align: center;
  }

  .w__label {
    font-size: 16px;
    font-weight: bold;
  }

  i {
    outline: none;
  }

  .w__modal-buttons {
    margin-top: 16px;
    display: flex;
    flex-direction: row;
    justify-content: center;

    .ui.button {
      display: flex;
      justify-content: center;
      width: 144px;
      height: 40px;
      font-size: 16px;
      margin: {
        right: 8px !important;
        left: 8px !important;
      }
    }
  }
}
