@import "../../../styles/index.scss";

.a__notfound {
  width: 100%;
  height: 100%;

  .w__notfound-header {
    padding: {
      bottom: 32px;
      left: 32px;
    }
    .b__title-header {
      margin-bottom: 16px;
      font-size: 32px;
      font-weight: bold;
    }
    .b__message-header {
      font-size: 16px;
    }
    .w__button {
      padding: {
        top: 20px;
      }
    }
  }
  .w__footer {
    width: 100%;
  }
}
