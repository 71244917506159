.b__stocks-detail__tab {
  display: flex;
  flex-wrap: wrap;

  .ui.button {
    width: 100%;
    height: 52px;
    background: #fff;

    &:hover,
    &:focus,
    &:active {
      background: none;
    }
  }

  .b__stocks-detail__list {
    width: 50%;
    list-style: none;

    &.active {
      .ui.button {
        color: #fff;
        background: #ffc107;
      }
    }
  }
}
