@import '../../../styles/index.scss';

@mixin makeButton() {
  color: white !important;
}

.edit {
  @include makeButton();

  background: {
    color: $app__success-color !important;
  }
}

.delete {
  @include makeButton();

  background: {
    color: $app__error-color !important;
  }
}
