@import "../../../styles/index.scss";

.a__sales-main {
  flex: 1;
  padding: {
    right: 24px;
    left: 24px;
  }

  .b__search {
    width: 100% !important;
  }

  .b__title {
    .title {
      font-family: 'UD Shin Go Medium', sans-serif;
    }
  }

  .csv_output {
    display: flex;
    align-items: center;

    &--clear {
      margin-right: 24px;
    }

    &--bid {
      margin-right: 24px;
    }
  }

  .b__create {
    padding: {
      top: 16px;
      bottom: 8px;
    }

    display: flex;
    flex: 1;
    justify-content: space-between;

    .totalCount {
      display: flex;
      align-items: flex-end;
      float: left;
      font-size: 14px;
      font-weight: bold;
      .count {
        color: $app__main-color;
      }
    }
    .a__button-round.yellow {
      .ui.button {
        color: $app__white-color;
        background: $app__main-color;
      }
    }
  }

  .b__action {
    margin: {
      top: 16px;
    }
  }

  .confirmed-center {
    padding: {
      left: 12px;
    }
  }

  .ui.checkbox {
    label {
      height: 16px;
      font-size: $app__font-size;
      color: $app__black-color !important;
      padding: {
        left: 24px;
      }

      &::before {
        background: $app__white-color;
        border: 1px solid $app__main-color !important;
        border-radius: 3px !important;
      }
      &::after {
        color: $app__white-color !important;
        background: $app__main-color;
        border: 1px solid $app__main-color !important;
        border-radius: 3px !important;
      }
    }
    &.disabled {
      label {
        cursor: not-allowed !important;
      }
    }
  }

  .status-auction {
    font-weight: bold;
    color: #7ea6f4;
  }

  .status-done {
    font-weight: bold;
    color: #01a9b4;
  }

  .status-expired {
    font-weight: bold;
    color: #e84a5f;
  }

  .status-canceled {
    font-weight: bold;
    color: #d4b5b0;
  }

  .status-unknown {
    font-weight: bold;
    color: #900c3f;
  }

  .status-error {
    font-weight: bold;
    color: #ffc107;
  }

  .text-bold {
    font-weight: bold;
  }

  .csv_explanation_button {
    &:hover {
      background: {
        color: $app__main-color;
      }

      opacity: 0.4;
      transition: {
        duration: 0.4;
      }
    }

    float: right;
    width: 70px;
    padding: 6px !important;
    font-size: 10px !important;
    color: gray !important;
    background-color: white !important;
    border: 1px solid !important;
    border-color: gray;
    border-radius: 20px !important;
  }

  .csv_output_button {
    margin-right: 20px !important;
  }
}

.w__explanation-modal {
  width: 770px !important;

  .a_csv-explanation {
    position: relative;
    height: 368px;

    .item::after {
      white-space: pre;
      content: ' ';
    }

    .item-title {
      font-size: 18px;
      font-weight: bold;
    }

    .item-body {
      font-size: 14px;
    }
  }
}

button.ui.button.csv_explanation_button {
  height: 30px !important;
}
