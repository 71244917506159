@import '../../../styles/index.scss';

.w__common-modal {
  .w__modal-text {
    text-align: center;
    margin: {
      top: 24px;
      bottom: 24px;
    }
  }
  .w__modal-buttons {
    display: flex;
    .a__button-block {
      width: 49%;
      &:nth-of-type(1) {
        margin: {
          right: auto;
        }
      }
      .ui.button {
        width: 100%;
      }
    }
  }
  .w__message {
    margin: {
      top: 20px;
      bottom: 20px;
    }
  }

  .w__modal-buttons.scroll {
    margin: {
      bottom: 20px;
    }
  }
  .w__modal-buttons {
    .b__common-button.isBelow {
      margin: {
        top: 8px;
      }
    }
  }
  .b__common-button {
    &.b__go-back {
      padding: {
        top: 16px;
      }
    }
  }
}
