@import '../../../styles/index.scss';

.a__button-round {
  .ui.button {
    border-radius: 25px;
  }
  &.disabled {
    &:hover {
      cursor: not-allowed;
    }
    opacity: 0.6;
  }
}
