@import '../../../styles/index.scss';

.b__form-label {
  display: flex;
  align-items: center;
  margin-bottom: 4px;
  font-size: 16px;
  font-weight: 700;
  color: #222;

  span {
    font-size: 10px;
  }

  .b__required-label {
    width: 28px;
    font-weight: bold;
    color: #fff;
    text-align: center;
    background: #ef6266;
    border-radius: 3px;

    margin: {
      top: 2px;
      bottom: 1px;
      left: 10px;
    }
  }

  .b__optional-label {
    width: 28px;
    font-weight: bold;
    color: #111;
    text-align: center;
    background: #d8d8d8;
    border-radius: 3px;

    margin: {
      top: 2px;
      bottom: 1px;
      left: 10px;
    }
  }
}
