@import '../../../styles/index.scss';

.ReactModal__Body--open {
  overflow: hidden;
}

.a__overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 200;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(#000, 0.45);
}

.a__modal-base {
  position: relative;
  z-index: 1;
  overflow: auto;
  background-color: $white;
  border-radius: 8px;
  outline: none;
  padding: {
    right: 40px;
    bottom: 32px;
    left: 40px;
  }

  &.xs {
    width: 40vw;
    max-width: 400px;
    min-height: 232px;
  }

  &.sm {
    width: 50vw;
  }

  &.md {
    min-width: 600px;
    max-width: 600px;
    min-height: 547px;
  }

  &.lg {
    width: 75vw;
  }

  &.xl {
    width: 95vw;
  }

  &.full {
    position: relative;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    padding: 16px;
    background-color: white;
  }

  .w__close {
    position: absolute;
    top: 16px;
    left: 24px;
    z-index: 999;
    cursor: pointer;
    outline: none;

    * {
      width: 18px;
    }
  }

  .w__title {
    width: 100%;
    text-align: center;
    padding: {
      top: 48px;
    }
  }

  .b__body {
    position: absolute;
    z-index: 2;
    width: 100%;
    padding: {
      top: 20px;
      bottom: 20px;
    }
  }
}
