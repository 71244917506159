@import '../../../styles/index.scss';

.ui.button.b__goback-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px !important;
  padding: 0;
  margin: {
    right: 8px !important;
  }

  i.arrow.left.icon {
    margin: {
      right: 0;
      bottom: 1px;
    }

    font-size: 16px;
  }
}
