@import '../../../styles/index.scss';

.a__date-picker {
  &.p-fluid {
    height: 100%;

    .p-calendar {
      height: 100%;
      border-radius: 4px;
      box-shadow: 0 0 2px rgba($form__main-color, 0.2);

      &.p-inputwrapper-focus {
        box-shadow: 0 0 0 3px rgba($form__main-color, 0.7);
      }

      .p-inputtext.p-component {
        height: 100%;
        border: none;
        outline: none;
        box-shadow: none;
        padding: {
          top: 0.678571em;
          right: 1em;
          bottom: 0.678571em;
          left: 1em;
        }

        &::placeholder {
          color: rgba(191, 191, 191, 0.87);
        }
      }

      .p-button.p-component.p-datepicker-trigger.p-calendar-button {
        border: none;
        outline: none;
        box-shadow: none;
        background: {
          color: white;
        }
      }

      .p-datepicker.p-component.p-input-overlay.p-shadow {
        max-width: 500px;

        .p-datepicker-group {
          .p-datepicker-header {
            padding: 0;
          }

          .p-datepicker-calendar-container {
            .p-datepicker-calendar {
              margin: 0;

              thead {
                tr {
                  th {
                    padding: {
                      top: 0;
                      bottom: 0;
                    }
                  }
                }
              }

              tbody {
                tr {
                  td {
                    padding: 0;

                    span {
                      padding: {
                        top: 0;
                        right: 10px;
                        bottom: 0;
                        left: 10px;
                      }

                      &:hover {
                        background: {
                          color: rgba($app__main-color, 0.3);
                        }
                      }
                      &.p-highlight {
                        background: {
                          color: $app__main-color;
                        }
                      }
                    }

                    &.p-datepicker-today {
                      span {
                        background: {
                          color: rgba(desaturate($app__main-color, 50%), 0.5);
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &.dark {
    .p-calendar {
      border: none;
      box-shadow: none;

      &.p-inputwrapper-focus {
        .p-inputtext.p-component {
          background: {
            color: $form__white-color;
          }
        }
      }

      .p-inputtext.p-component {
        background: {
          color: $form__dark-color;
        }
      }
    }
  }
}
