.a__stocks-detail {
  padding: 20px 30px 40px;

  .title {
    margin-bottom: 20px;
  }

  &__form {
    width: 640px;

    .exhibit {
      display: flex;
      &.hidden {
        display: none;
      }
    }

    .stocks {
      display: flex;
      &.hidden {
        display: none;
      }
    }
  }
}
