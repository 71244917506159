@import '../../../styles/index.scss';

.a__table-editable-cell {
  width: 100%;
  background-color: transparent;
  border: 1px solid gray;
  border-radius: 4px;

  &.error {
    border-color: $app__error-color;
  }

  &[type='number']::-webkit-outer-spin-button,
  &[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type='number'] {
    -moz-appearance: textfield;
  }
}
