@import '../../../styles/index.scss';

.table {
  position: relative;
  display: inline-block;
  min-width: 100%;
  max-width: 100%;
  overflow-x: auto;
  border-spacing: 0;
  border-spacing: 0;
  border-collapse: collapse;
  background-color: white;
  border-radius: 6px;
  box-shadow: 0 0 2px #dedede;

  .thead {
    border: {
      bottom: 3px solid #efefef;
    }

    min-width: 100%;
  }

  .tr {
    &:nth-child(odd) {
      background: #f8f8f8;
    }

    min-width: 100%;
    &:not(:last-child) {
      border: {
        bottom: 1px solid #efefef;
      }
    }

    &:hover {
      background: rgba($app__main-color, 0.1);
    }
  }

  .tbody {
    min-height: 440px;
    .tr {
      display: flex;
      align-items: center;
    }
  }

  .th,
  .td {
    position: relative;
    display: flex;
    align-items: center;
    height: 100%;
    padding: {
      top: 10px;
      right: 16px;
      bottom: 10px;
      left: 16px;
    }

    .resizer {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 1;
      display: inline-block;
      width: 4px;
      height: 100%;
      touch-action: none;
      transform: translateX(50%);
      &:hover {
        background: {
          color: $app__main-color;
        }

        opacity: 0.4;
        transition: {
          duration: 0.4;
        }
      }
    }
  }

  .loading {
    pointer-events: none;
    opacity: 0.5;
  }

  .loader {
    position: absolute;
    bottom: 50%;
    left: 50%;
    z-index: 100000;
  }

  .empty-message {
    position: absolute;
    top: 48%;
    width: 100%;
    text-align: center;
  }
}
